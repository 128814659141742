// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga';
import Login from './views/Login';
import Home from './views/Home';
import Cliente from './views/Cliente';
import Colaborador from './views/Colaborador';
import Estabelecimento from './views/Estabelecimento';
import Produto from './views/Produto';
import Visita from './views/Visita';
import CockPitGeral from './views/CockPitGeral';
import Visualizar from './views/Visualizar';
import Relatorio from './views/Relatorio';
import Ponto from './views/Ponto';
import { AuthProvider } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-BQT8PW41ZX');
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send("pageview");
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route path="/cliente" element={<ProtectedRoute element={<Cliente />} />} />
          <Route path="/colaborador" element={<ProtectedRoute element={<Colaborador />} />} />
          <Route path="/estabelecimento" element={<ProtectedRoute element={<Estabelecimento />} />} />
          <Route path="/produto" element={<ProtectedRoute element={<Produto />} />} />
          <Route path="/Ponto" element={<ProtectedRoute element={<Ponto/>} />} />
          <Route path="/Relatorio" element={<ProtectedRoute element={<Relatorio />} />} />
          <Route path="/visita/:id" element={<ProtectedRoute element={<Visualizar />} />} />
          <Route path="/visita" element={<ProtectedRoute element={<Visita mode="create" />} />} />
          <Route path="/cockpitgeral" element={<ProtectedRoute element={<CockPitGeral />} />} />
          <Route path="/visita/:id/editar" element={<ProtectedRoute element={<Visita mode="edit" />} />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
