import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CabecarioIcon from '../../components/CabecarioIcon';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import GtinTable from '../../components/tabelagtin/GtinTable';
import ProdutoService from '../../services/Produto/ProdutoService';
import ProdutoDel from '../../services/Produto/ProdutoDel';
import buscaProdutoPorGTIN from '../../services/apiCosmos/BuscaProduto';
import useBuscaProdutos from '../../services/Produto/BuscaProduto';
import secureStorage from '../../utils/SecureStorage';

const CadastroProduto = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [erro, setErro] = useState('');
  const [activeTab, setActiveTab] = useState(0); // Estado para controlar a aba ativa
  const intl = useIntl();
  const contentLabel = intl.formatMessage({ id: 'form.buscar_cadastros', defaultMessage: 'Buscar Cadastros' });
  const [nome, setNome] = useState('');
  const { searchResults, loadregistros } = useBuscaProdutos([]);
  const [produto, setProduto] = useState({
    id_rede: secureStorage.getItem("id_r"),
    description: '',
    gtin: '',
    thumbnail: '',
    price: '',
    avg_price: '',
    max_price: '',
    min_price: '',
    width: '',
    height: '',
    length: '',
    net_weight: '',
    gross_weight: '',
    brand: {
      name: '',
      picture: ''
    },
    gpc: {
      code: '',
      description: ''
    },
    ncm: {
      code: '',
      description: '',
      full_description: ''
    },
    cest: {
      id: '',
      code: '',
      description: '',
      parent_id: ''
    },
    gtins: [
      {
        gtin: '',
        commercial_unit: {
          type_packaging: '',
          quantity_packaging: '',
          ballast: '',
          layer: ''
        }
      },
      {
        gtin: '',
        commercial_unit: {
          type_packaging: '',
          quantity_packaging: '',
          ballast: '',
          layer: ''
        }
      }
    ]
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
  
    if (type === 'file') {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === 'thumbnail') {
          setProduto(prevState => ({
            ...prevState,
            thumbnail: reader.result
          }));
        } else if (name === 'brand.picture') {
          setProduto(prevState => ({
            ...prevState,
            brand: {
              ...prevState.brand,
              picture: reader.result
            }
          }));
        }
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else {
      const [key, subKey] = name.split('.');
      if (subKey) {
        setProduto(prevState => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            [subKey]: value
          }
        }));
      } else {
        setProduto(prevState => ({
          ...prevState,
          [name]: value
        }));
      }
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (produto.gtin.length >= 8) {
        try {
          const produtoData = await buscaProdutoPorGTIN(produto.gtin);
          setProduto({
            ...produto,
            description: produtoData.description || '',
            thumbnail: produtoData.thumbnail || '',
            price: produtoData.price || '',
            avg_price: produtoData.avg_price || '',
            max_price: produtoData.max_price || '',
            min_price: produtoData.min_price || '',
            width: produtoData.width || '',
            height: produtoData.height || '',
            length: produtoData.length || '',
            net_weight: produtoData.net_weight || '',
            gross_weight: produtoData.gross_weight || '',
            brand: {
              name: produtoData.brand?.name || '',
              picture: produtoData.brand?.picture || ''
            },
            gpc: {
              code: produtoData.gpc?.code || '',
              description: produtoData.gpc?.description || ''
            },
            ncm: {
              code: produtoData.ncm?.code || '',
              description: produtoData.ncm?.description || '',
              full_description: produtoData.ncm?.full_description || ''
            },
            cest: {
              id: produtoData.cest?.id || '',
              code: produtoData.cest?.code || '',
              description: produtoData.cest?.description || '',
              parent_id: produtoData.cest?.parent_id || ''
            },
            gtins: produtoData.gtins?.map((gtinData) => ({
              gtin: gtinData.gtin,
              commercial_unit: {
                type_packaging: gtinData.commercial_unit?.type_packaging || '',
                quantity_packaging: gtinData.commercial_unit?.quantity_packaging || '',
                ballast: gtinData.commercial_unit?.ballast || '',
                layer: gtinData.commercial_unit?.layer || ''
              }
            })) || []
          });
          setErro('');
        } catch (error) {
          console.error("Erro ao buscar o produto no Cosmos:", error);
          setErro("Erro ao buscar o produto no Cosmos. Produto não encontrado.");
        }
      } else {
        setErro("O GTIN deve ter no minimo 8 caracteres.");
      }
    }
  };

  // Função para mover o foco para o próximo campo ou aba
  const moveFocus = (e, nextFieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextField = document.getElementsByName(nextFieldName)[0];
      if (nextField) {
        nextField.focus();
      } else {
        // Se não houver próximo campo, muda para a próxima aba
        setActiveTab((prevTab) => {
          const nextTab = prevTab + 1;
          return nextTab >= 6 ? 0 : nextTab; // Cicla entre as abas
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!produto.description) {
      alert("Você precisa informar a descriçâo do produto ");
      return false;
    } 

    if (!produto.brand.name) {
      alert("Você precisa informar o nome da marca ");
      return false;
    }

    try {
      await ProdutoService.saveProduto(produto);
      handleResetForm();
      setActiveTab(0); // Volta para a primeira aba após salvar
    } catch (error) {
      console.error("Erro ao salvar o produto:", error);
      setErro(intl.formatMessage({ id: 'form.error.saving', defaultMessage: 'Erro ao salvar o cadastro' }));
    }
  };

  const handleResetForm = () => {
    setProduto({
      id_rede: secureStorage.getItem("id_r"),
      description: '',
      gtin: '',
      thumbnail: '',
      price: '',
      avg_price: '',
      max_price: '',
      min_price: '',
      width: '',
      height: '',
      length: '',
      net_weight: '',
      gross_weight: '',
      brand: {
        name: '',
        picture: ''
      },
      gpc: {
        code: '',
        description: ''
      },
      ncm: {
        code: '',
        description: '',
        full_description: ''
      },
      cest: {
        id: '',
        code: '',
        description: '',
        parent_id: ''
      },
      gtins: [
        {
          gtin: '',
          commercial_unit: {
            type_packaging: '',
            quantity_packaging: '',
            ballast: '',
            layer: ''
          }
        },
        {
          gtin: '',
          commercial_unit: {
            type_packaging: '',
            quantity_packaging: '',
            ballast: '',
            layer: ''
          }
        }
      ]
    });
    setErro('');
    setActiveTab(0); // Volta para a primeira aba após resetar
  };

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistros(idrede); // Carrega os registros ao abrir o modal
    }
  };

  const handleSearchChange = (event) => {
    setNome(event.target.value);
  };

  const handleSelectProduto = (produtoSelecionado) => {
    setProduto(produtoSelecionado); // Atualiza o estado com os dados do produto selecionado
    handleCloseModal(); // Fecha o modal
    setErro(''); // Limpa mensagens de erro, se aplicável
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      const produtoVazio = await ProdutoDel.excluirProduto(produto._id.$oid);
      setProduto(produtoVazio);
      setActiveTab(0); // Volta para a primeira aba após excluir
    } catch (error) {
      console.error('Erro ao excluir o produto:', error);
      setErro('Erro ao excluir o produto. Veja o console para mais detalhes.');
    }
  };

  const handleDeleteImage = (imageType) => {
    setProduto(prevState => ({
      ...prevState,
      [imageType]: ''
    }));
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.Title>
            <FormattedMessage id="form.title_p" defaultMessage="Cadastro de Produtos" />
          </S.Title>
          <CabecarioIcon title="Cadastro de Produtos" />
          <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
            <S.StyledTabList>
              <S.StyledTab>
                <FormattedMessage id="tabs.produto" defaultMessage="Produto" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.Marca" defaultMessage="Marca" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.gpc" defaultMessage="Gpc" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.ncm" defaultMessage="Ncm" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.cest" defaultMessage="Cest" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.gtins" defaultMessage="Gtins" />
              </S.StyledTab>
            </S.StyledTabList>
            <TabPanel>
              {/* Campos básicos */}
              <S.GtinImageGroup>
                <S.InputGroup style={{ flex: 0.5 }}>
                  <S.Label>
                    <FormattedMessage id="form.gtin" defaultMessage="Gtin:" />
                  </S.Label>
                  <S.Input
                    name="gtin"
                    value={produto.gtin}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress} // Chama a função de busca ao pressionar Enter
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    
                  />
                  <S.Label>
                    <FormattedMessage id="form.ncm_full_description" defaultMessage="Descrição Completa NCM:" />
                  </S.Label>
                  <S.LabelGray>
                    {produto.ncm.full_description}
                  </S.LabelGray>
                </S.InputGroup>
                <S.InputGroup style={{ flex: 0.5 }}>
                  <S.Label>
                    <FormattedMessage id="form.picture" defaultMessage="Imagem:" />
                  </S.Label>
                  {produto.thumbnail ? (
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <img src={produto.thumbnail} alt="Selected" style={{ width: '50%' }} />
                      <button
                        type="button"
                        onClick={() => handleDeleteImage('thumbnail')}
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          background: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer'
                        }}
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    <S.Input
                      type="file"
                      name="thumbnail"
                      accept="image/*"
                      onChange={handleChange}
                    />
                  )}
                </S.InputGroup>
              </S.GtinImageGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.description" defaultMessage="Descrição:" />
                </S.Label>
                <S.Input
                  name="description"
                  value={produto.description}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'price')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.PriceGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.price" defaultMessage="Preço:" />
                  </S.Label>
                  <S.Input
                    name="price"
                    value={produto.price}
                    onChange={handleChange}
                    onKeyDown={(e) => moveFocus(e, 'avg_price')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.avg_price" defaultMessage="Preço Médio:" />
                  </S.Label>
                  <S.Input
                    name="avg_price"
                    value={produto.avg_price}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'max_price')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.max_price" defaultMessage="Preço Máximo:" />
                  </S.Label>
                  <S.Input
                    name="max_price"
                    value={produto.max_price}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'min_price')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.min_price" defaultMessage="Preço Mínimo:" />
                  </S.Label>
                  <S.Input
                    name="min_price"
                    value={produto.min_price}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'width')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
              </S.PriceGroup>
              <S.DimensionGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.width" defaultMessage="Largura:" />
                  </S.Label>
                  <S.Input
                    name="width"
                    value={produto.width}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'height')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.height" defaultMessage="Altura:" />
                  </S.Label>
                  <S.Input
                    name="height"
                    value={produto.height}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'length')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.length" defaultMessage="Comprimento:" />
                  </S.Label>
                  <S.Input
                    name="length"
                    value={produto.length}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'net_weight')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
              </S.DimensionGroup>
              <S.WeightGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.net_weight" defaultMessage="Peso Líquido:" />
                  </S.Label>
                  <S.Input
                    name="net_weight"
                    value={produto.net_weight}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'gross_weight')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>
                    <FormattedMessage id="form.gross_weight" defaultMessage="Peso Bruto:" />
                  </S.Label>
                  <S.Input
                    name="gross_weight"
                    value={produto.gross_weight}
                    onChange={handleChange}
                    type="number" // Garante que apenas números sejam aceitos
                    pattern="[0-9]*" // Garante que apenas números sejam aceitos
                    onKeyDown={(e) => moveFocus(e, 'brand.name')} // Mover o foco para o próximo campo
                  />
                </S.InputGroup>
              </S.WeightGroup>

              {/* Adicione o link aqui */}
              {/* <S.ExternalLink href="https://cosmos.bluesoft.com.br/" target="_blank">
                Informações de cadastro de produtos
              </S.ExternalLink> */}
            </TabPanel>
            <TabPanel>
              {/* Marca */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.name" defaultMessage="Nome:" />
                </S.Label>
                <S.Input
                  name="brand.name"
                  value={produto.brand.name}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'brand.picture')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.picture" defaultMessage="Imagem:" />
                </S.Label>
                {produto.brand.picture ? (
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img src={produto.brand.picture} alt="Brand" style={{ width: '50%' }} />
                    <button
                      type="button"
                      onClick={() => handleDeleteImage('brand.picture')}
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '50%',
                        cursor: 'pointer'
                      }}
                    >
                      X
                    </button>
                  </div>
                ) : (
                  <S.Input
                    type="file"
                    name="brand.picture"
                    accept="image/*"
                    onChange={handleChange}
                    onKeyDown={(e) => moveFocus(e, 'gpc.code')} // Mover o foco para a próxima aba
                  />
                )}
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Gpc */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.gpc_code" defaultMessage="Código GPC:" />
                </S.Label>
                <S.Input
                  name="gpc.code"
                  value={produto.gpc.code}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'gpc.description')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.gpc_description" defaultMessage="Descrição GPC:" />
                </S.Label>
                <S.Input
                  name="gpc.description"
                  value={produto.gpc.description}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'ncm.code')} // Mover o foco para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Ncm */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.ncm_code" defaultMessage="Código NCM:" />
                </S.Label>
                <S.Input
                  name="ncm.code"
                  value={produto.ncm.code}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'ncm.description')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.ncm_description" defaultMessage="Descrição NCM:" />
                </S.Label>
                <S.Input
                  name="ncm.description"
                  value={produto.ncm.description}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'ncm.full_description')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.ncm_full_description" defaultMessage="Descrição Completa NCM:" />
                </S.Label>
                <S.Input
                  name="ncm.full_description"
                  value={produto.ncm.full_description}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cest.id')} // Mover o foco para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Cest */}
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cest_id" defaultMessage="ID CEST:" />
                </S.Label>
                <S.Input
                  name="cest.id"
                  value={produto.cest.id}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cest.code')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cest_code" defaultMessage="Código CEST:" />
                </S.Label>
                <S.Input
                  name="cest.code"
                  value={produto.cest.code}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cest.description')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cest_description" defaultMessage="Descrição CEST:" />
                </S.Label>
                <S.Input
                  name="cest.description"
                  value={produto.cest.description}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cest.parent_id')} // Mover o foco para o próximo campo
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cest_parent_id" defaultMessage="ID Pai CEST:" />
                </S.Label>
                <S.Input
                  name="cest.parent_id"
                  value={produto.cest.parent_id}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'gtins.gtin')} // Mover o foco para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
            <TabPanel>
              {/* Gtins */}
              <GtinTable gtins={produto.gtins} setGtins={(newGtins) => setProduto({ ...produto, gtins: newGtins })} />
            </TabPanel>
          </Tabs>
          {erro && <S.Erro>{erro}</S.Erro>}
          <S.ButtonGroup>
            <S.Button type="button" onClick={handleOpenModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
            { secureStorage.getItem("TipoProduto") !== 1 && (
            <S.Button type="submit">
              <FormattedMessage id="form.cadastrar" defaultMessage="Cadastrar" />
            </S.Button>
            )}
            {produto._id && secureStorage.getItem("TipoProduto") === 3 && (
              <S.Button type="button" onClick={handleDelete}>
                <FormattedMessage id="form.excluir" defaultMessage="Excluir Cadastro" />
              </S.Button>
            )}
            { secureStorage.getItem("TipoProduto") !== 1 && (
            <S.Button type="button" onClick={handleResetForm}>
              <FormattedMessage id="form.novo" defaultMessage="Novo" />
            </S.Button>
            )}
          </S.ButtonGroup>
        </S.Form>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          <S.Input
            type="text"
            placeholder="Digite o nome para buscar..."
            value={nome}
            onChange={handleSearchChange}
            style={{ marginBottom: '10px' }}
          />
          <S.ModalContent>
            {Array.isArray(searchResults) && searchResults.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>GTIN</th>
                    <th>Preço</th>
                    <th>Marca</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults
                    .filter(produto =>
                      produto.description.toLowerCase().includes(nome.toLowerCase())
                    )
                    .map((produto) => (
                      <tr key={produto.gtin} onClick={() => handleSelectProduto(produto)}>
                        <td>{produto.description}</td>
                        <td>{produto.description}</td>
                        <td>{produto.gtin}</td>
                        <td>{produto.price}</td>
                        <td>{produto.brand.name}</td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </S.ModalContent>
          <button onClick={handleCloseModal}>Fechar</button>
        </S.StyledModal>
      </S.Container>
      <Footer />
    </>
  );
};

export default CadastroProduto;
