import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as S from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CabecarioIcon from '../../components/CabecarioIcon';
import { Tabs, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useBuscaEstabelecimento from '../../services/Estabelecimento/useBuscaEstabelecimento';
import EstabelecimentoService from '../../services/Estabelecimento/EstabelecimentoService';
import ViaCepService from '../../services/Viacep/ViaCepService';
import Del from '../../services/Estabelecimento/del';
import { MascaraCpfCnpj, ValidaCpf, ValidaCnpj, MascaraCep, MascaraTelefone, MascaraTelefoneFixo, ValidaEmail } from '../../utils/mask';
import secureStorage from '../../utils/SecureStorage';

const CadastroEstabelecimento = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { searchResults, loadregistros } = useBuscaEstabelecimento([]);
  const [erro, setErro] = useState('');
  const intl = useIntl();
  const contentLabel = intl.formatMessage({ id: 'form.buscar_cadastros', defaultMessage: 'Buscar Cadastros' });
  const [nome_fantasia, setNome_fantasia] = useState('');
  const [cpf_cnpj, setCpfCnpj] = useState('');
  const apiUrl = process.env.REACT_APP_API_open;

  const [activeTab, setActiveTab] = useState(0);

  const [estabelecimento, setEstabelecimento] = useState({
    id: '',
    id_rede: secureStorage.getItem("id_r"),
    cpf_cnpj: '',
    nome_fantasia: '',
    Razao_Social: '',
    blocked: false,
    inactive: false,
    endereco: {
      rua: '',
      numero: '',
      complemento: '',
      bairro: '',
      cep: '',
      cidade: '',
      estado: '',
      latitude: '',
      longitude: ''
    },
    contato: {
      telefone_fixo: '',
      celular: '',
      email: '',
      WhatsApp: '',
      observacoes: ''
    },
  });

  const [addressFetched, setAddressFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleChange = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case 'contato.celular':
      case 'contato.WhatsApp':
        value = MascaraTelefone(value);
        break;
      case 'contato.telefone_fixo':
        value = MascaraTelefoneFixo(value);
        break;
      case 'endereco.cep':
        value = MascaraCep(value);
        break;
      case 'cpf_cnpj':
        value = MascaraCpfCnpj(value);
        break;
      default:
        break;
    }

    if (name.includes('endereco.') || name.includes('contato.')) {
      const [parentKey, childKey] = name.split('.');
      setEstabelecimento(prevState => ({
        ...prevState,
        [parentKey]: {
          ...prevState[parentKey],
          [childKey]: value
        }
      }));
    } else {
      setEstabelecimento(prevState => ({
        ...prevState,
        [name]: value
      }));
    }

    if (name === 'endereco.numero') {
      setAddressFetched(false);
      setFetchError(false);
    }
  };

  // Função para mover o foco para o próximo campo ou aba
  const moveFocus = (e, nextFieldName) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextField = document.getElementsByName(nextFieldName)[0];
      if (nextField) {
        nextField.focus();
      } else {
        // Se não houver próximo campo, muda para a próxima aba
        setActiveTab((prevTab) => {
          const nextTab = prevTab + 1;
          return nextTab >= 3 ? 0 : nextTab; // Cicla entre as abas
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cpf_cnpj = MascaraCpfCnpj(estabelecimento.cpf_cnpj);

    if (!ValidaCpf(cpf_cnpj) && !ValidaCnpj(cpf_cnpj)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_cpf_cnpj', defaultMessage: 'CPF/CNPJ inválido' }));
      return;
    }

    if (estabelecimento.contato.email && !ValidaEmail(estabelecimento.contato.email)) {
      setErro(intl.formatMessage({ id: 'form.error.invalid_email', defaultMessage: 'Email inválido' }));
      return;
    }
    
    if (!estabelecimento.nome_fantasia) {
      alert("Você precisa informar o Nome do Cliente");
      return false;
    }
  
    // Verifica se a rua do endereço foi informada
    if (!estabelecimento.endereco.rua) {
      alert("Você precisa informar a Rua do Endereço");
      return false;
    }
  
    // Verifica se o número do endereço foi informado
    if (!estabelecimento.endereco.numero) {
      alert("Você precisa informar o Número do Endereço");
      return false;
    }
  
    // Verifica se o bairro do endereço foi informado
    if (!estabelecimento.endereco.bairro) {
      alert("Você precisa informar o Bairro do Endereço");
      return false;
    }
  
    // Verifica se o CEP do endereço foi informado
    if (!estabelecimento.endereco.cep) {
      alert("Você precisa informar o CEP do Endereço");
      return false;
    }
  
    // Verifica se a cidade do endereço foi informada
    if (!estabelecimento.endereco.cidade) {
      alert("Você precisa informar a Cidade do Endereço");
      return false;
    }
  
    // Verifica se o estado do endereço foi informado
    if (!estabelecimento.endereco.estado) {
      alert("Você precisa informar o Estado do Endereço");
      return false;
    }

    try {
      await EstabelecimentoService.saveEstabelecimento(estabelecimento);
      handleResetForm();
      setActiveTab(0);
    } catch (error) {
      console.error("Erro ao salvar o estabelecimento:", error);
      setErro(intl.formatMessage({ id: 'form.error.saving', defaultMessage: 'Erro ao salvar o cadastro' }));
    }
  };

  const handleOpenModal = async () => {
    setIsModalOpen(true);
    const idrede = secureStorage.getItem("id_r");
    if (idrede) {
      await loadregistros(idrede);
    }
  };

  const handleSearchChange = (event, setSearchTerm) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectEstabelecimento = (estabelecimentoSelecionado) => {
    setEstabelecimento(estabelecimentoSelecionado);
    handleCloseModal();
    setErro('');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = () => {
    const estabelecimentoId = estabelecimento._id?.$oid;
    Del.excluirEstabelecimento(estabelecimentoId)
      .then(estabelecimentoVazio => {
        setEstabelecimento(estabelecimentoVazio);
        setActiveTab(0);
      })
      .catch(error => {
        console.error('Erro ao excluir o estabelecimento:', error);
      });
  };

  const fetchLatLong = async (address) => {
    const url = `${apiUrl}${address}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        setEstabelecimento(prevState => ({
          ...prevState,
          endereco: {
            ...prevState.endereco,
            latitude: lat,
            longitude: lon,
          }
        }));
        setAddressFetched(true);
        setFetchError(false);
      } else {
        alert('Geolocalização não encontrado.');
        setFetchError(true);
      }
    } catch (error) {
      alert('Erro ao buscar a localização: ' + error.message);
      setFetchError(true);
    }
  };

  useEffect(() => {
    const { rua, numero, bairro, cidade, estado } = estabelecimento.endereco;
    if (rua && numero && bairro && cidade && estado && !addressFetched && !fetchError) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const address = `${rua}, ${numero}, ${bairro}, ${cidade}, ${estado}`;
      setDebounceTimer(setTimeout(() => fetchLatLong(address), 500)); // 500ms delay
    }
  }, [estabelecimento.endereco]);

  const onBlurCep = async (e) => {
    let { value } = e.target;
    const cep = value.replace(/\D/g, '');

    if (cep.length !== 8) {
      return;
    }

    try {
      const data = await ViaCepService.fetchAddress(cep);
      if (data.erro) {
        alert('CEP não encontrado.');
        return;
      }
      setEstabelecimento(prevState => ({
        ...prevState,
        endereco: {
          ...prevState.endereco,
          rua: data.logradouro,
          bairro: data.bairro,
          cidade: data.localidade,
          estado: data.uf,
          cep: value,
        }
      }));
      setAddressFetched(false);
      setFetchError(false);
    } catch (error) {
      alert('Erro ao buscar CEP: ' + error.message);
    }
  };

  const handleResetForm = () => {
    setEstabelecimento({
      id: '',
      id_rede: secureStorage.getItem("id_r"),
      cpf_cnpj: '',
      nome_fantasia: '',
      Razao_Social: '',
      blocked: false,
      inactive: false,
      endereco: {
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cep: '',
        cidade: '',
        estado: '',
        latitude: '',
        longitude: ''
      },
      contato: {
        telefone_fixo: '',
        celular: '',
        email: '',
        WhatsApp: '',
        observacoes: ''
      },
    });
    setActiveTab(0);
    setAddressFetched(false);
    setFetchError(false);
    setDebounceTimer(null);
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Form onSubmit={handleSubmit}>
          <S.Title>
            <FormattedMessage id="form.titleEsta" defaultMessage="Cadastro de Estabelecimento"/>
          </S.Title>
          <CabecarioIcon titleEsta="Cadastro de Estabelecimento"/>
          <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)}>
            <S.StyledTabList>
              <S.StyledTab>
                <FormattedMessage id="tabs.Estabelecimento" defaultMessage="Estabelecimento"/>
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.address" defaultMessage="Endereço" />
              </S.StyledTab>
              <S.StyledTab>
                <FormattedMessage id="tabs.contact" defaultMessage="Contato" />
              </S.StyledTab>
            </S.StyledTabList>
            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.nameFantasia" defaultMessage="Nome Fantasia:*" />
                </S.Label>
                <S.Input
                  name="nome_fantasia"
                  value={estabelecimento.nome_fantasia}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'Razao_Social')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.Razao_Social" defaultMessage="Razao Social:" />
                </S.Label>
                <S.Input
                  name="Razao_Social"
                  value={estabelecimento.Razao_Social}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'cpf_cnpj')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cpf_cnpj" defaultMessage="CPF/CNPJ:" />
                </S.Label>
                <S.Input
                  name="cpf_cnpj"
                  value={estabelecimento.cpf_cnpj}
                  onChange={handleChange}
                  maxLength={18}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.CheckboxContainer>
                  <S.Label>Bloquear:</S.Label>
                  <S.Checkbox name="blocked" type="checkbox" checked={estabelecimento.blocked} onChange={(e) => setEstabelecimento({ ...estabelecimento, blocked: e.target.checked })} />
                </S.CheckboxContainer>
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cep" defaultMessage="Cep:" />
                </S.Label>
                <S.Input
                  name="endereco.cep"
                  value={estabelecimento.endereco.cep}
                  onChange={handleChange}
                  onBlur={onBlurCep}
                  maxLength={9}
                  onKeyDown={(e) => moveFocus(e, 'endereco.rua')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.rua" defaultMessage="Rua:" />
                </S.Label>
                <S.Input
                  name="endereco.rua"
                  value={estabelecimento.endereco.rua}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.numero')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.numero" defaultMessage="Numero:" />
                </S.Label>
                <S.Input
                  name="endereco.numero"
                  value={estabelecimento.endereco.numero}
                  onChange={handleChange}
                  maxLength={10}
                  onBlur={(e) => setAddressFetched(false)}
                  onKeyDown={(e) => moveFocus(e, 'endereco.complemento')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.complemento" defaultMessage="Complemento:" />
                </S.Label>
                <S.Input
                  name="endereco.complemento"
                  value={estabelecimento.endereco.complemento}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.bairro')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.bairro" defaultMessage="Bairro:" />
                </S.Label>
                <S.Input
                  name="endereco.bairro"
                  value={estabelecimento.endereco.bairro}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.cidade')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.cidade" defaultMessage="Cidade:" />
                </S.Label>
                <S.Input
                  name="endereco.cidade"
                  value={estabelecimento.endereco.cidade}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'endereco.estado')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.estado" defaultMessage="Estado:" />
                </S.Label>
                <S.Input
                  name="endereco.estado"
                  value={estabelecimento.endereco.estado}
                  onChange={handleChange}
                  maxLength={2}
                  onKeyDown={(e) => moveFocus(e, 'endereco.latitude')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.latitude" defaultMessage="Latitude:" />
                </S.Label>
                <S.Input
                  name="endereco.latitude"
                  value={estabelecimento.endereco.latitude}
                  onChange={handleChange}
                  pattern="^-?[0-9]*[.,]?[0-9]*$"
                  onKeyDown={(e) => moveFocus(e, 'endereco.longitude')}
                  //readOnly
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.longitude" defaultMessage="Longitude:" />
                </S.Label>
                <S.Input
                  name="endereco.longitude"
                  value={estabelecimento.endereco.longitude}
                  onChange={handleChange}
                  pattern="^-?[0-9]*[.,]?[0-9]*$"
                  onKeyDown={(e) => moveFocus(e, '')} 
                  //readOnly
                />
              </S.InputGroup>
            </TabPanel>

            <TabPanel>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.telefone_fixo" defaultMessage="Telefone fixo:" />
                </S.Label>
                <S.Input
                  name="contato.telefone_fixo"
                  value={estabelecimento.contato.telefone_fixo}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.celular')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.celular" defaultMessage="Celular:" />
                </S.Label>
                <S.Input
                  name="contato.celular"
                  value={estabelecimento.contato.celular}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.WhatsApp')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.whatsapp" defaultMessage="WhatsApp:" />
                </S.Label>
                <S.Input
                  name="contato.WhatsApp"
                  value={estabelecimento.contato.WhatsApp}
                  onChange={handleChange}
                  onKeyDown={(e) => moveFocus(e, 'contato.email')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.email" defaultMessage="Email:" />
                </S.Label>
                <S.Input
                  name="contato.email"
                  value={estabelecimento.contato.email}
                  onChange={handleChange}
                  maxLength={255}
                  onKeyDown={(e) => moveFocus(e, 'contato.observacoes')}
                />
              </S.InputGroup>
              <S.InputGroup>
                <S.Label>
                  <FormattedMessage id="form.observacoes" defaultMessage="Observações:" />
                </S.Label>
                <S.TextArea
                  name="contato.observacoes"
                  value={estabelecimento.contato.observacoes}
                  onChange={handleChange}
                  maxLength={250}
                  onKeyDown={(e) => moveFocus(e, '')} // Último campo da aba, moverá para a próxima aba
                />
              </S.InputGroup>
            </TabPanel>
          </Tabs>
          {erro && <S.Erro>{erro}</S.Erro>}
          <S.ButtonGroup>
            <S.Button type="button" onClick={handleOpenModal}>
              <FormattedMessage id="form.buscar_cadastros" defaultMessage="Buscar Cadastros" />
            </S.Button>
            { secureStorage.getItem("TipoEstabelecimento") !== 1 && (
            <S.Button type="submit">
              <FormattedMessage
                id={estabelecimento._id ? "form.salvar" : "form.cadastrar"}
                defaultMessage={estabelecimento._id ? "Salvar" : "Cadastrar"}
              />
            </S.Button>
            )}
            {estabelecimento._id &&  secureStorage.getItem("TipoEstabelecimento") === 3 && (
              <S.Button type="button" onClick={handleDelete}>
                <FormattedMessage id="form.excluir" defaultMessage="Excluir Cadastro" />
              </S.Button>
            )}
            { secureStorage.getItem("TipoEstabelecimento") !== 1 && (
            <S.Button type="button" onClick={handleResetForm}>
              <FormattedMessage id="form.novo" defaultMessage="Novo" />
            </S.Button>
            )}
          </S.ButtonGroup>
        </S.Form>
        <S.StyledModal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel={contentLabel}
          ariaHideApp={false}
        >
          <S.Input
            type="text"
            placeholder="Digite o nome para buscar..."
            value={nome_fantasia}
            onChange={(e) => handleSearchChange(e, setNome_fantasia)}
            style={{ marginBottom: '10px' }}
          />
          <S.Input
            type="text"
            placeholder="Digite o CPF/CNPJ para buscar..."
            value={cpf_cnpj}
            onChange={(e) => handleSearchChange(e, setCpfCnpj)}
          />
          <S.ModalContent>
            {Array.isArray(searchResults) && searchResults.length > 0 ? (
              <S.StyledTable>
                <thead>
                  <tr>
                    <th>Nome_fantasia</th>
                    <th>Razao_Social</th>
                    <th>cpf_cnpj</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults
                    .filter(estabelecimento =>
                      estabelecimento.nome_fantasia.toLowerCase().includes(nome_fantasia.toLowerCase()) &&
                      estabelecimento.cpf_cnpj.toLowerCase().includes(cpf_cnpj.toLowerCase())
                    )
                    .map((estabelecimento) => (
                      <tr key={estabelecimento.cpf_cnpj} onClick={() => handleSelectEstabelecimento(estabelecimento)}>
                        <td>{estabelecimento.nome_fantasia}</td>
                        <td>{estabelecimento.Razao_Social}</td>
                        <td>{estabelecimento.cpf_cnpj}</td>
                      </tr>
                    ))}
                </tbody>
              </S.StyledTable>
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </S.ModalContent>
          <button onClick={handleCloseModal}>Fechar</button>
        </S.StyledModal>
      </S.Container>
      <Footer />
    </>
  );
};

export default CadastroEstabelecimento;
