import api from '../api';
import secureStorage from '../../utils/SecureStorage';

const EstabelecimentoService = {
  removeMask: (value, length = null) => {
    if (!value) return '';
    let newValue = value.replace(/\D/g, '');
    return length ? newValue.slice(-length) : newValue;
  },

  validateFields: (estabelecimento) => {
    const { nome_fantasia, id_rede, contato, endereco } = estabelecimento;
    if (!nome_fantasia) {
      alert("Você precisa informar o Nome do Estabelecimento");
      return false;
    } else if (!id_rede || id_rede.length < 10) {
      alert("Ocorreu um problema. Por favor, entre em contato com o suporte.");
      return false;
    } else if (!endereco.rua || !endereco.numero || !endereco.bairro || !endereco.cep || !endereco.cidade || !endereco.estado) {
      alert("Você precisa informar o endereço completo");
      return false;
    }
    return true;
  },

  saveEstabelecimento: async (estabelecimento) => {
    if (!EstabelecimentoService.validateFields(estabelecimento)) return;

    estabelecimento.cpf_cnpj = EstabelecimentoService.removeMask(estabelecimento.cpf_cnpj);
    estabelecimento.endereco.cep = EstabelecimentoService.removeMask(estabelecimento.endereco.cep);
    //estabelecimento.contato.telefone_fixo = EstabelecimentoService.removeMask(estabelecimento.contato.telefone_fixo, 11);
    //estabelecimento.contato.celular = EstabelecimentoService.removeMask(estabelecimento.contato.celular, 12);
    estabelecimento.id_rede = secureStorage.getItem("id_r");
    estabelecimento.data_update = new Date();
    const _id = estabelecimento._id?.$oid;
    const { ...estabelecimentoData } = estabelecimento;
    const apiUr = process.env.REACT_APP_API_BASE_3;
    const apiUrl = process.env.REACT_APP_API_BASE_4;

    try {
      if (_id) {
        estabelecimentoData.id = _id;
        await api.put(`${apiUrl}${_id}?id_rede=${estabelecimento.id_rede}`, estabelecimentoData);
        alert("Cadastro Editado Com Sucesso!");
      } else {
        await api.post(apiUrl, estabelecimentoData);
        alert("Cadastro Salvo Com Sucesso!");
      }
    } catch (error) {
      console.error("Erro ao salvar o cadastro:", error);
      if (error.response && error.response.data && error.response.data.error) {
        alert("Erro ao salvar o cadastro: " + error.response.data.error);
      } else {
        alert("Erro ao salvar o cadastro.");
      }
    }

    // return {
    //   id: '',
    //   cpf_cnpj: '',
    //   id_rede: '',
    //   nome_fantasia: '',
    //   Razao_Social: '',
    //   blocked: false,
    //   inactive: false,
    //   endereco: {
    //     rua: '',
    //     numero: '',
    //     complemento: '',
    //     bairro: '',
    //     cep: '',
    //     cidade: '',
    //     estado: '',
    //     latitude: '',
    //     longitude: ''
    //   },
    //   contato: {
    //     telefone_fixo: '',
    //     celular: '',
    //     email: '',
    //     WhatsApp: '',
    //     observacoes: ''
    //   },
    // };
  }
};

export default EstabelecimentoService;
