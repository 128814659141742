import React, { useState, useEffect } from 'react';
import * as S from './styles'; // Importe os estilos
import { FaCheckCircle, FaTimesCircle, FaMapMarkerAlt, FaPlus } from 'react-icons/fa';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BuscaPonto from '../../services/ponto/BuscaPonto';
import PontoService from '../../services/ponto/PontoService';
import secureStorage from '../../utils/SecureStorage';
import Modal from 'react-modal';

// Custom hook to set the map view to the current location
function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
}

// Set the app element for accessibility
Modal.setAppElement('#root');

function Ponto() {
    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradorAtivo, setColaboradorAtivo] = useState(null);
    const [localizacaoAtiva, setLocalizacaoAtiva] = useState(null);
    const [colaboradoresUnicos, setColaboradoresUnicos] = useState([]);
    const { loadregistros } = BuscaPonto([]);
    const [saldoHorasColaborador, setSaldoHorasColaborador] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalAjusteIsOpen, setModalAjusteIsOpen] = useState(false);
    const [registroSelecionado, setRegistroSelecionado] = useState(null);

    // Função para carregar os registros de ponto
    const loadregistrosponto = async () => {
        const id_rede = secureStorage.getItem("id_r");
        if (id_rede) {
            const error = await loadregistros(id_rede);
            if (error) {
                console.error("Erro ao carregar registros:", error);
            } else if (secureStorage.getItem("dataponto")) {
                let dataUser = secureStorage.getItem("dataponto");
                if (dataUser) {
                    dataUser = JSON.parse(dataUser);
                    const colaboradores = Object.keys(dataUser).map((colaborador) => ({
                        colaborador,
                        registros: dataUser[colaborador]
                    }));
                    setColaboradores(colaboradores);
                    setColaboradoresUnicos(Object.keys(dataUser));
                    setColaboradorAtivo(colaboradores[0]);
                }
            }
        }
    };

    useEffect(() => {
        loadregistrosponto();
    }, []);

    useEffect(() => {
        if (colaboradorAtivo) {
            calcularSaldoHoras(colaboradorAtivo.registros);
        }
    }, [colaboradorAtivo]);

    const getDiaDaSemana = (data) => {
        const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const date = new Date(data);
        return diasDaSemana[date.getDay()];
    };

    const abrirMapa = (localizacao) => {
        setLocalizacaoAtiva(localizacao);
        setModalIsOpen(true);
    };

    const fecharMapa = () => {
        setLocalizacaoAtiva(null);
        setModalIsOpen(false);
    };

    const abrirAjusteManual = (registro) => {
        setRegistroSelecionado(registro);
        setModalAjusteIsOpen(true);
    };

    const fecharAjusteManual = () => {
        setRegistroSelecionado(null);
        setModalAjusteIsOpen(false);
    };

    // Função para calcular as horas trabalhadas
    const calcularHorasTrabalhadas = (batidas) => {
        let horasTrabalhadas = 0;
        for (let i = 0; i < batidas.length - 1; i += 2) {
            const inicio = new Date(batidas[i].timestamp);
            const fim = new Date(batidas[i + 1].timestamp);
            horasTrabalhadas += (fim - inicio) / (1000 * 60 * 60);
        }
        return horasTrabalhadas.toFixed(2);
    };

    const calcularSaldoHoras = (registros) => {
        let saldoHoras = 0;
        registros.forEach(registro => {
            const horasTrabalhadas = calcularHorasTrabalhadas(registro.batidas);
            saldoHoras += horasTrabalhadas - 8; 
        });
        setSaldoHorasColaborador(saldoHoras.toFixed(2));
    };

    const renderBatida = (batida) => {
        return batida ? (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span>{new Date(batida.timestamp).toLocaleTimeString()}</span>
                <button 
                    style={{ marginLeft: '8px', padding: '4px', cursor: 'pointer' }} 
                    onClick={(e) => { e.stopPropagation(); abrirMapa(batida.localizacao); }}>
                    <FaMapMarkerAlt />
                </button>
            </div>
        ) : '---';
    };
    

    const renderBatidasColunas = (batidas) => {
        return batidas.map((batida, index) => (
            <td key={index}>
                {renderBatida(batida)}
            </td>
        ));
    };

    const renderStatus = (batidas) => {
        if (!batidas || batidas.length % 2 !== 0) {
            return <FaTimesCircle color="red" />;
        }
        return <FaCheckCircle color="green" />;
    };

    const getMaxBatidas = () => {
        let maxBatidas = 0;
        colaboradores.forEach(colaborador => {
            colaborador.registros.forEach(registro => {
                if (registro.batidas.length > maxBatidas) {
                    maxBatidas = registro.batidas.length;
                }
            });
        });
        return maxBatidas;
    };

    const maxBatidas = getMaxBatidas();

    const defaultIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    const getSaldoHorasStyle = () => {
        if (saldoHorasColaborador >= 0) {
            return { color: '#4CAF50' }; // Verde para positivo
        } else {
            return { color: '#F44336' }; // Vermelho para negativo
        }
    };

    
    const salvarAjuste = async () => {
        const horario = document.getElementById('horarioBatida').value;
    
        // Verifica se o horário foi preenchido
        if (!horario || horario === "--:--") {
            alert("Por favor, insira um horário válido antes de salvar.");
            return; // Sai da função se o horário não for informado
        }
    
        if (registroSelecionado) {
            const novaBatida = {
                timestamp: new Date(`${registroSelecionado.data}T${horario}:00`).toISOString(),
                localizacao: { latitude: "-24.9562597", longitude: "-53.4557773", precisao: 0 },
                Registromanual: new Date().toISOString() // Indica ajuste manual
            };
    
            // Adiciona a nova batida e ordena por horário
            const batidasAtualizadas = [...registroSelecionado.batidas, novaBatida].sort(
                (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
            );
    
            // Preparar o objeto para enviar ao serviço PontoService
            const clienteData = {
                id_rede: secureStorage.getItem("id_r"),
                empresa: colaboradorAtivo.registros[0]?.empresa || "empresa_exemplo",
                colaborador: colaboradorAtivo.colaborador,
                id_colaborador: colaboradorAtivo.registros[0]?.id_colaborador,
                data: registroSelecionado.data,
                batidas: batidasAtualizadas, // Batidas atualizadas e ordenadas
            };
    
            try {
                await PontoService.saveponto(clienteData);
                alert("Ajuste salvo com sucesso!");
    
                // Atualiza o estado local com os registros atualizados
                const registrosAtualizados = colaboradores.map((colaborador) => {
                    if (colaborador.colaborador === colaboradorAtivo.colaborador) {
                        const registros = colaborador.registros.map((reg) => {
                            if (reg.data === registroSelecionado.data) {
                                return { ...reg, batidas: batidasAtualizadas };
                            }
                            return reg;
                        });
                        return { ...colaborador, registros };
                    }
                    return colaborador;
                });
    
                setColaboradores(registrosAtualizados);
                fecharAjusteManual();
                loadregistrosponto();
            } catch (error) {
                console.error("Erro ao salvar o ajuste:", error);
                alert("Erro ao salvar o ajuste. Tente novamente.");
            }
        }
    };
    

    return (
        <S.Container>
            <Header />
            <S.Content>
                <S.Tabs>
                    {colaboradoresUnicos.map((colaborador, index) => (
                        <S.Tab
                            key={index}
                            isActive={colaborador === colaboradorAtivo?.colaborador}
                            onClick={() => {
                                const colaboradorSelecionado = colaboradores.find(c => c.colaborador === colaborador);
                                if (colaboradorSelecionado) {
                                    setColaboradorAtivo({
                                        colaborador: colaboradorSelecionado.colaborador,
                                        id_colaborador: colaboradorSelecionado.registros[0]?.id_colaborador,
                                        empresa: colaboradorSelecionado.registros[0]?.empresa,
                                        registros: colaboradorSelecionado.registros
                                    });
                                }
                            }}>
                            {colaborador}
                        </S.Tab>
                    ))}
                </S.Tabs>
    
                {/* Exibir o Total de Horas aqui, entre as abas e a tabela */}
                <S.SaldoTotal style={getSaldoHorasStyle()}>Total de horas: {saldoHorasColaborador}</S.SaldoTotal>
    
                <S.TableContainer>
                    <S.Table>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Dia da Semana</th>
                                {[...Array(maxBatidas)].map((_, i) => (
                                    <th key={i}>{i + 1}º Registro</th>
                                ))}
                                <th>Total de Horas</th>
                                <th>Status</th>
                                <th>Adicionar Registro</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colaboradorAtivo && colaboradorAtivo.registros.map((registro, index) => (
                                <tr key={index}>
                                    <td>{registro.data}</td>
                                    <td>{getDiaDaSemana(registro.data)}</td>
                                    {renderBatidasColunas(registro.batidas)}
                                    {[...Array(maxBatidas - registro.batidas.length)].map((_, i) => (
                                        <td key={i} className="sem-registro">Sem registro</td>
                                    ))}
                                    <td>{calcularHorasTrabalhadas(registro.batidas)}</td>
                                    <td>{renderStatus(registro.batidas)}</td>
                                    <td>
                                        <button onClick={() => abrirAjusteManual(registro)} className="botao-adicionar">
                                            <FaPlus />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </S.Table>
                </S.TableContainer>
    
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={fecharMapa}
                    contentLabel="Localização da Batida"
                    style={S.mapModalStyle}
                >
                    {localizacaoAtiva && (
                        <>
                            <h3>Localização da Batida</h3>
                            <S.MapContainerWrapper>
                                <MapContainer center={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} zoom={18} style={{ height: "100%", width: "100%" }}>
                                    <TileLayer attribution='&copy; OpenStreetMap contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    <Marker position={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} icon={defaultIcon}>
                                        <Popup>
                                            Localização da batida: {localizacaoAtiva.latitude}, {localizacaoAtiva.longitude}
                                        </Popup>
                                    </Marker>
                                    <ChangeMapView coords={[localizacaoAtiva.latitude, localizacaoAtiva.longitude]} />
                                </MapContainer>
                            </S.MapContainerWrapper>
                            <button onClick={fecharMapa}>Fechar Mapa</button>
                        </>
                    )}
                </Modal>
    
                <Modal
                    isOpen={modalAjusteIsOpen}
                    onRequestClose={fecharAjusteManual}
                    contentLabel="Ajuste Manual"
                    style={S.modalStyle}
                >
                    {registroSelecionado && (
                        <S.ModalContent>
                            <h3>Ajustar Registro de Ponto - {registroSelecionado.data}</h3>
                            <div className="field">
                                <label>Horário: </label>
                                <input type="time" id="horarioBatida" />
                            </div>
                            <div className="actions">
                                <button onClick={salvarAjuste}>Salvar</button>
                                <button onClick={fecharAjusteManual}>Cancelar</button>
                            </div>
                        </S.ModalContent>
                    )}
                </Modal>
            </S.Content>
            <Footer />
        </S.Container>
    );
    
    
    
    
}

export default Ponto;
